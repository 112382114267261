<template>
  <dog-dialog
    ref="dogDialog"
    title="修改邮箱"
    width="400px"
    @confirm="confirm"
    @closed="closed"
    :append-element-id="false"
  >
    <identity-check ref="identityCheck" @input="dataChange"></identity-check>
    <el-divider></el-divider>
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      label-width="80px"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import userService from '@/api/service/user';
  import emailCode from '@/components/emailCode.vue';
  import identityCheck from '@/components/identityCheck.vue';
  import md5 from 'js-md5';

  export default {
    name: 'changeEmailDialog',
    components: { identityCheck },
    data() {
      return {
        formData: {}
      };
    },
    computed: {
      formOptions() {
        return [
          {
            type: 'input',
            formItem: {
              label: '新邮箱',
              prop: 'newEmail',
              rules: [
                this.$formRules.required('请输入新邮箱'),
                this.$formRules.email()
              ]
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '验证码',
              prop: 'newCode',
              rules: [this.$formRules.required('请输入验证码')]
            },
            component: emailCode,
            attrs: {
              email: this.formData.email
            }
          }
        ];
      }
    },
    methods: {
      openFrame() {
        this.$refs.dogDialog.open();
      },
      dataChange(val) {
        this.formData = { ...this.formData, ...val };
      },
      /**
       * 修改密码
       */
      confirm() {
        this.$refs.identityCheck
          .validate()
          .then(() => {
            return this.$refs.dogForm.validate();
          })
          .then(() => {
            const params = {
              checkType: this.formData.checkType,
              password: this.formData.password && md5(this.formData.password),
              code: this.formData.code,
              newEmail: this.formData.newEmail,
              newCode: this.formData.newCode
            };
            userService.editEmail(params).then(() => {
              this.close();
              setTimeout(() => {
                this.$store.dispatch('setToken', {});
                window.location.replace('/login');
              }, 1000);
            });
          });
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
