<template>
  <div class="flex_row layout">
    <nav-menu :menus="menus || []"></nav-menu>
    <div class="flex_column flex_1">
      <right-header @refresh="refresh"></right-header>
      <tag-view></tag-view>
      <div class="layout__main flex_column flex_1">
        <div class="layout__main__body flex_1 flex_column">
          <keep-alive :include="cachedNames">
            <router-view
              :key="$route.path"
              id="router"
              v-if="isShow"
            ></router-view>
          </keep-alive>
        </div>
      </div>
      <right-footer></right-footer>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import navMenu from './navMenu.vue';
  import rightHeader from './rightHeader.vue';
  import rightFooter from './rightFooter.vue';
  import tagView from './tagView.vue';

  export default {
    name: 'layout',
    components: {
      navMenu,
      rightHeader,
      rightFooter,
      tagView
    },
    data() {
      return {
        isShow: true
      };
    },
    computed: {
      ...mapGetters(['menus', 'cachedNames'])
    },
    methods: {
      refresh() {
        this.isShow = false;
        this.$store.dispatch('removeCachedNames', [this.$route.name]);
        this.$nextTick(() => {
          this.isShow = true;
          this.$store.dispatch('addCacheName', [this.$route.name]);
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @include b(layout) {
    height: 100%;
    @include e(main) {
      padding: 10px;
      background-color: $background-color-base;
      @include e(body) {
        padding: 10px;
        background-color: $color-white;
      }
    }
  }
</style>
