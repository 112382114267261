<template>
  <div class="tagView flex_row">
    <!-- <i class="el-icon-arrow-left"></i> -->
    <el-tabs
      v-model="activePath"
      closable
      @tab-click="clickTab"
      @tab-remove="removeTab"
      class="tagView__tabs flex_1"
    >
      <el-tab-pane
        v-for="menu in visitedMenus"
        :key="menu.path"
        :label="menu.title"
        :name="menu.path"
      >
      </el-tab-pane>
    </el-tabs>
    <!-- <i class="el-icon-arrow-right"></i> -->
    <el-dropdown @command="handleCommand">
      <span class="tagView__dropdown">
        <i class="el-icon-arrow-down"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="clearLeft">
          <i class="el-icon-back"></i> 关闭左侧
        </el-dropdown-item>
        <el-dropdown-item command="clearRight">
          <i class="el-icon-right"></i> 关闭右侧
        </el-dropdown-item>
        <el-dropdown-item command="clearOthers">
          <i class="el-icon-close"></i> 关闭其他
        </el-dropdown-item>
        <el-dropdown-item command="clearAll">
          <i class="el-icon-circle-close"></i> 关闭全部
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'tagView',
    data() {
      return {
        activePath: ''
      };
    },
    computed: {
      ...mapGetters(['visitedMenus', 'activeMenu'])
    },
    watch: {
      activeMenu: {
        handler() {
          this.activePath = this.activeMenu ? this.activeMenu.path : '';
        },
        immediate: true
      }
    },
    mounted() {},
    methods: {
      /**
       * 点击标签页
       * @param {object} tab 标签实例
       */
      clickTab(tab) {
        this.$router.push({
          path: tab.name
        });
      },
      /**
       * 移除标签页
       * @param {string} name 标签 name
       */
      removeTab(name) {
        if (
          this.$store.getters.visitedMenus.length === 1 &&
          this.activePath === this.$store.getters.homePath
        ) {
          return;
        }
        let removedMenu;
        const visitedMenus = this.$store.getters.visitedMenus.filter((item) => {
          if (item.path === name) {
            removedMenu = item;
          }
          return item.path !== name;
        });
        this.$store.dispatch('setVisitedMenus', visitedMenus);
        this.$store.dispatch('removeCachedNames', [removedMenu.name]);
        if (this.activeMenu.path === name) {
          this.toFirstMenu();
        }
      },
      /**
       * 跳转到第一个标签页，或者首页
       */
      toFirstMenu() {
        if (this.$store.getters.visitedMenus.length > 0) {
          this.$router.push({
            path: this.$store.getters.visitedMenus[0].path
          });
        } else {
          this.$router.push({
            path: this.$store.getters.homePath
          });
        }
      },
      /**
       * 执行下拉选择
       * @param {string} command 命令名
       */
      handleCommand(command) {
        this[command]();
      },
      /**
       * 关闭左侧标签页
       */
      clearLeft() {
        const { leftPart, rightPart } = this.getVisitedMenusDivide();
        const visitedMenus = [this.$store.getters.activeMenu, ...rightPart];
        this.$store.dispatch('setVisitedMenus', visitedMenus);
        this.$store.dispatch(
          'removeCachedNames',
          leftPart.map((item) => item.name)
        );
      },
      /**
       * 关闭右侧标签页
       */
      clearRight() {
        const { leftPart, rightPart } = this.getVisitedMenusDivide();
        const visitedMenus = [...leftPart, this.$store.getters.activeMenu];
        this.$store.dispatch('setVisitedMenus', visitedMenus);
        this.$store.dispatch(
          'removeCachedNames',
          rightPart.map((item) => item.name)
        );
      },
      /**
       * 关闭其他标签页
       */
      clearOthers() {
        const { leftPart, rightPart } = this.getVisitedMenusDivide();
        const otherMenus = [...leftPart, ...rightPart];
        const visitedMenus = [this.$store.getters.activeMenu];
        this.$store.dispatch('setVisitedMenus', visitedMenus);
        this.$store.dispatch(
          'removeCachedNames',
          otherMenus.map((item) => item.name)
        );
      },
      /**
       * 关闭全部标签页
       */
      clearAll() {
        if (this.activePath === this.$store.getters.homePath) {
          this.$store.dispatch(
            'removeCachedNames',
            this.$store.getters.visitedMenus
              .filter((item) => {
                return item.path !== this.activePath;
              })
              .map((item) => item.name)
          );
          this.$store.dispatch('setVisitedMenus', [
            this.$store.getters.activeMenu
          ]);
          return;
        }
        this.$store.dispatch(
          'removeCachedNames',
          this.$store.getters.visitedMenus.map((item) => item.name)
        );
        this.$store.dispatch('setVisitedMenus', []);
        this.$router.push({
          path: this.$store.getters.homePath
        });
      },
      /**
       * 获取左右部分的标签页
       * @returns {array} leftPart 左侧标签页
       * @returns {array} rightPart 右侧标签页
       */
      getVisitedMenusDivide() {
        let leftPart = [];
        let rightPart = [];
        let isLeft = true;
        for (let i = 0; i < this.$store.getters.visitedMenus.length; i++) {
          if (
            this.$store.getters.activeMenu.path ===
            this.$store.getters.visitedMenus[i].path
          ) {
            isLeft = false;
            continue;
          }
          if (isLeft) {
            leftPart.push(this.$store.getters.visitedMenus[i]);
          } else {
            rightPart.push(this.$store.getters.visitedMenus[i]);
          }
        }
        return { leftPart, rightPart };
      }
    }
  };
</script>

<style lang="scss" scoped>
  $height: 40px;
  $fontSize: 18px;
  $padding: 12px;
  @include b(tagView) {
    height: 40px;
    align-items: center;
    border-bottom: $border-base;
    & > i {
      padding: $padding;
      cursor: pointer;
    }
    @include e(tabs) {
      width: 0;
    }
    @include e(dropdown) {
      padding: $padding;
      font-size: $fontSize;
      cursor: pointer;
    }
  }
  /deep/ .el-tabs {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__nav-wrap {
      padding: 0 40px;
      &::after {
        height: 0;
      }
    }
    .el-tabs__active-bar {
      width: 0 !important;
    }
    .el-tabs__item {
      color: $color-text-secondary;
      &.is-top:nth-child(2) {
        padding-left: 20px;
      }
      &.is-top:last-child {
        padding-right: 20px;
      }
      &:hover {
        color: $color-text-primary;
        background-color: $background-color-hover;
      }
      &.is-active {
        color: $color-primary;
        background-color: $background-color-active;
        border-bottom: none;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #1890ff;
        content: '';
      }
      &.is-active::after {
        width: 100%;
      }
      .el-icon-close:hover {
        color: #fff;
        background-color: #ff4d4f;
      }
    }
    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      padding: 0 $padding;
      font-size: $fontSize;
      line-height: $height;
      &.is-disabled {
        cursor: pointer;
      }
    }
  }
</style>
