<template>
  <div class="navMenu flex_column">
    <div
      class="navMenu__logo"
      :class="isCollapse ? 'collapse' : ''"
      @click="goHome"
    >
      <img src="@/assets/images/shop.png" alt="logo" :title="title" />
      <span>{{ title }}</span>
    </div>
    <dog-scroll-box class="navMenu__menu flex_1">
      <el-menu :default-active="activePath" :collapse="isCollapse">
        <div v-for="menu in menus" :key="menu.id">
          <nav-sub-menu :menu="menu"></nav-sub-menu>
        </div>
      </el-menu>
    </dog-scroll-box>
  </div>
</template>

<script>
  import navSubMenu from './navSubMenu.vue';

  export default {
    name: 'navMenu',
    components: { navSubMenu },
    props: {
      menus: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        activePath: '',
        isCollapse: false,
        title: '店铺管理系统'
      };
    },
    watch: {
      $route: {
        handler(to) {
          this.findActivePath(this.menus, to);
          this.$store.dispatch('addCacheName', to.name);
        },
        immediate: true
      }
    },
    mounted() {
      window.Subject.listen('navCollapse', (isCollapse) => {
        this.isCollapse = isCollapse;
      });
    },
    methods: {
      /**
       * 获取当前标签页路径，并保存标签页到状态管理
       * @param {array} menus 当前菜单
       * @param {object} to 当前路由
       * @param {object} parent 父级菜单
       */
      findActivePath(menus, to, parent = {}) {
        for (let i = 0; i < menus.length; i++) {
          if (menus[i].path === to.path) {
            if (parent.type === 'menu') {
              this.activePath = parent.path;
              this.$store.dispatch('setActiveMenu', parent);
              this.updateVisitedMenus(parent);
            } else {
              this.activePath = menus[i].path;
              this.$store.dispatch('setActiveMenu', menus[i]);
              this.updateVisitedMenus(menus[i]);
            }
            return;
          } else if (Array.isArray(menus[i].children)) {
            this.findActivePath(menus[i].children, to, menus[i]);
          }
        }
      },
      /**
       * 更新已访问菜单信息
       * @param {object} activeMenu 当前菜单
       */
      updateVisitedMenus(activeMenu) {
        const hasMenu = this.$store.getters.visitedMenus.find((item) => {
          return item.path === activeMenu.path;
        });
        if (!hasMenu) {
          this.$store.dispatch('setVisitedMenus', [
            ...this.$store.getters.visitedMenus,
            activeMenu
          ]);
        }
      },
      /**
       * 跳转到首页
       */
      goHome() {
        this.$router.push({
          path: this.$store.getters.homePath
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(navMenu) {
    @include e(logo) {
      width: 250px;
      height: 60px;
      line-height: 60px;
      color: $color-white;
      text-align: center;
      cursor: pointer;
      background-color: $color-dark;
      // border-right: solid 1px #e6e6e6;
      transition: 0.3s width ease-in-out;
      &.collapse {
        width: 64px;
      }
      img {
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
      span {
        margin-left: 10px;
        font-weight: bold;
        vertical-align: middle;
      }
    }
    @include e(menu) {
      background-color: $color-dark;
    }
  }
</style>
