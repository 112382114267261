<template>
  <div class="identityCheck">
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      label-width="80px"
    ></dog-form>
  </div>
</template>

<script>
  import emailCode from '@/components/emailCode.vue';

  export default {
    name: 'identityCheck',
    data() {
      return {
        formData: {}
      };
    },
    computed: {
      checkTypeOptions() {
        const emailCheck = this.$store.getters.userInfo.email
          ? [{ label: '邮箱验证', value: 'email' }]
          : [];
        return [{ label: '密码验证', value: 'password' }, ...emailCheck];
      },
      formOptions() {
        const checkType = {
          email: [
            {
              type: 'custom',
              formItem: {
                label: '验证码',
                prop: 'code',
                rules: [this.$formRules.required('请输入验证码')]
              },
              component: emailCode,
              attrs: {
                email: this.$store.getters.userInfo.email
              }
            }
          ],
          password: [
            {
              type: 'input',
              formItem: {
                label: '密码',
                prop: 'password',
                rules: [this.$formRules.required('请输入密码')]
              },
              attrs: {
                type: 'password',
                showPassword: true
              }
            }
          ]
        };
        const check = checkType[this.formData.checkType] || [];
        return [
          {
            type: 'select',
            formItem: {
              label: '验证方式',
              prop: 'checkType',
              rules: [this.$formRules.required('请选择验证方式')]
            },
            attrs: {
              options: this.checkTypeOptions,
              clearable: false,
              default: 'password'
            }
          },
          ...check
        ];
      }
    },
    watch: {
      formData: {
        handler(newVal) {
          this.$emit('input', newVal);
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      validate() {
        return this.$refs.dogForm.validate();
      }
    }
  };
</script>

<style lang="scss" scoped></style>
