<template>
  <div class="rightHeader flex_row">
    <div class="rightHeader__leftPart flex_1 flex_row">
      <i
        class="pointer"
        :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        @click="triggerCollapse"
      ></i>
      <i class="el-icon-refresh-right pointer" @click="refresh"></i>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          :to="{ path: '/' }"
          v-if="
            activeMenusWay.length > 0 &&
            activeMenusWay[activeMenusWay.length - 1].path !== homePath
          "
          >主页</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-for="menu in activeMenusWay"
          :key="menu.path"
          :to="{ path: menu.path }"
          >{{ menu.title }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="rightHeader__rightPart flex_row">
      <el-dropdown @command="handleCommand">
        <span class="rightHeader__rightPart__avatar flex_row">
          <el-avatar v-if="userInfo.avatar" :src="userInfo.avatar"></el-avatar>
          <el-avatar v-else icon="el-icon-user-solid"></el-avatar>
          <span class="marginLeft1 marginRight1">{{
            userInfo.userName || '游客'
          }}</span>
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="toPersonalCenter">
            <i class="el-icon-user"></i> 个人中心
          </el-dropdown-item>
          <el-dropdown-item command="changePassword">
            <i class="el-icon-key"></i> 修改密码
          </el-dropdown-item>
          <el-dropdown-item command="changeEmail">
            <i class="el-icon-eleme"></i> 修改邮箱
          </el-dropdown-item>
          <el-dropdown-item divided command="logout">
            <i class="el-icon-switch-button"></i> 退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <change-psd-dialog ref="changePsdDialog"></change-psd-dialog>
    <change-email-dialog ref="changeEmailDialog"></change-email-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import changePsdDialog from './changePsdDialog.vue';
  import changeEmailDialog from './changeEmailDialog.vue';

  export default {
    name: 'rightHeader',
    components: { changePsdDialog, changeEmailDialog },
    data() {
      return {
        activeMenusWay: [],
        isCollapse: false
      };
    },
    computed: {
      ...mapGetters(['menus', 'activeMenu', 'homePath', 'userInfo'])
    },
    watch: {
      activeMenu: {
        handler() {
          this.findActiveMenusWay(this.menus);
        },
        immediate: true
      }
    },
    methods: {
      /**
       * 查找路由路径
       * @param {array} menus 当前菜单
       * @param {array} parent 父级菜单
       */
      findActiveMenusWay(menus = [], parent = []) {
        for (let i = 0; i < menus.length; i++) {
          if (menus[i].path === this.activeMenu.path) {
            this.activeMenusWay = [...parent, menus[i]];
          }
          if (Array.isArray(menus[i].children)) {
            this.findActiveMenusWay(menus[i].children, [...parent, menus[i]]);
          }
        }
      },
      /**
       * 触发展开/收起导航
       */
      triggerCollapse() {
        this.isCollapse = !this.isCollapse;
        window.Subject.trigger('navCollapse', this.isCollapse);
      },
      /**
       * 刷新当前页
       */
      refresh() {
        this.$emit('refresh');
      },
      /**
       * 执行下拉选择
       * @param {string} command 命令名
       */
      handleCommand(command) {
        this[command]();
      },
      /**
       * 跳转到个人中心
       */
      toPersonalCenter() {
        this.$router.push({
          path: '/personalCenter/profile'
        });
      },
      /**
       * 修改密码
       */
      changePassword() {
        this.$refs.changePsdDialog.openFrame();
      },
      /**
       * 修改邮箱
       */
      changeEmail() {
        this.$refs.changeEmailDialog.openFrame();
      },
      /**
       * 退出登录
       */
      logout() {
        this.$confirm('确定要退出登录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('setToken', {});
          window.location.replace('/login'); // 这样跳转避免再次登录重复注册动态路由
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  $el-padding: 0 12px;
  @include b(rightHeader) {
    height: 60px;
    background-color: $color-white;
    border-bottom: $border-base;
    @include e(leftPart) {
      padding: $el-padding;
      align-items: center;
      i {
        padding: $el-padding;
        font-size: 18px;
      }
      .el-breadcrumb {
        padding: $el-padding;
      }
    }
    @include e(rightPart) {
      padding: $el-padding;
      align-items: center;
      i {
        padding: $el-padding;
        font-size: 18px;
      }
      @include e(avatar) {
        cursor: pointer;
        align-items: center;
        .el-avatar {
          width: 28px;
          height: 28px;
          line-height: 28px;
        }
        i {
          padding: 0;
        }
      }
    }
  }
</style>
