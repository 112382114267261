<template>
  <div>
    <el-submenu
      :index="menu.path"
      v-if="menu.type === 'folder' && menu.visible === '1'"
    >
      <div slot="title" class="ellipsis1">
        <i :class="menu.icon" class="iconfont"></i>
        <span :title="menu.title">{{ menu.title }}</span>
      </div>
      <div v-for="subMenu in menu.children" :key="subMenu.id">
        <nav-sub-menu :menu="subMenu"></nav-sub-menu>
      </div>
    </el-submenu>
    <el-menu-item
      :index="menu.path"
      v-else-if="menu.type === 'menu' && menu.visible === '1'"
      class="ellipsis1"
      @click="linkTo(menu)"
    >
      <i :class="menu.icon" class="iconfont"></i>
      <span slot="title" :title="menu.title">{{ menu.title }}</span>
    </el-menu-item>
    <el-menu-item
      :index="menu.path"
      v-else-if="menu.type === 'outerChain' && menu.visible === '1'"
      class="ellipsis1"
      @click="linkTo(menu)"
    >
      <i :class="menu.icon" class="iconfont"></i>
      <span slot="title" :title="menu.title">{{ menu.title }}</span>
    </el-menu-item>
  </div>
</template>

<script>
  export default {
    name: 'navSubMenu',
    props: {
      menu: {
        type: Object,
        default: () => ({})
      }
    },
    methods: {
      linkTo(menu) {
        if (menu.type === 'outerChain') {
          window.open(menu.chain);
          return;
        }
        if (menu.blank === '1') {
          let routeData = this.$router.resolve({
            path: menu.path
          });
          window.open(routeData.href, '_blank');
        } else {
          this.$router.push({
            path: menu.path
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
