<template>
  <dog-dialog
    ref="dogDialog"
    title="修改密码"
    width="400px"
    @confirm="confirm"
    @closed="closed"
    :append-element-id="false"
  >
    <identity-check ref="identityCheck" @input="dataChange"></identity-check>
    <el-divider></el-divider>
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      label-width="80px"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import userService from '@/api/service/user';
  import identityCheck from '@/components/identityCheck.vue';
  import md5 from 'js-md5';

  export default {
    name: 'changePsdDialog',
    components: { identityCheck },
    data() {
      return {
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '新密码',
              prop: 'newPassword',
              rules: [this.$formRules.required('请输入新密码')]
            },
            attrs: {
              type: 'password',
              showPassword: true
            }
          },
          {
            type: 'input',
            formItem: {
              label: '确认密码',
              prop: 'newPassword2',
              rules: [this.$formRules.required('请再次输入新密码')]
            },
            attrs: {
              placeholder: '请再次输入新密码',
              type: 'password',
              showPassword: true
            }
          }
        ]
      };
    },
    methods: {
      openFrame() {
        this.$refs.dogDialog.open();
      },
      dataChange(val) {
        this.formData = { ...this.formData, ...val };
      },
      /**
       * 修改密码
       */
      confirm() {
        if (this.formData.newPassword !== this.formData.newPassword2) {
          this.$message.error('两次新密码不一致！');
          return;
        }
        this.$refs.identityCheck
          .validate()
          .then(() => {
            return this.$refs.dogForm.validate();
          })
          .then(() => {
            const params = {
              checkType: this.formData.checkType,
              password: this.formData.password && md5(this.formData.password),
              code: this.formData.code,
              newPassword: md5(this.formData.newPassword)
            };
            userService.editPassword(params).then(() => {
              this.close();
              setTimeout(() => {
                this.$store.dispatch('setToken', {});
                window.location.replace('/login');
              }, 1000);
            });
          });
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
